<template>
  <div id="app" class="app">
    <nav class="navbar">
        <img class="logo" src="./assets/logo.png" alt="">
        <div class="logo-text">卡友藏</div>
        <div class="menu_list">
          <div class="btn" :class="active == index ? 'active' : ''" @click="nextFn(item, index)" v-for="(item, index) in navList" :key="item.key">{{  item.name }}</div>
        </div>
    </nav>
    <router-view />
    <footer class="footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">ICP备案/许可证号:桂ICP备2023006697号</a>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      active: 0,
      navList: [
        { name: "首页", key: "home", router: "/" },
        { name: "详情介绍", key: "about", router: "/about" }
      ]
    }
  },
  mounted() {
    console.log(this.$router.history._startLocation)
    let a = this.$router.history._startLocation;
    if(this.$router.history._startLocation) {
      this.navList.forEach((e, index) => {
        if(a == e.router) {
          this.active = index;
        }
      })
    }
  },
  methods: {
    nextFn(item, index) {
      this.active = index;
      if(this.$route.fullPath != item.router) {
        this.$router.push(item.router);
      }
    }
  }
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}
.app {
  position: relative;
}
.navbar {
    width: 100%;
    height: 80px;
    background: rgba(189, 173, 145, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding-left: 36px;
    display: flex;
    align-items: center;
    .logo {
        width: 40px;
        height: 40px;
    }

    .logo-text {
      color: rgba(122, 88, 24, 1);
      font-weight: 600;
      font-size: 30px;
      margin-left: 6px;
    }

    .menu_list {
        display: flex;
        padding-left: 60px;
        .btn {
            height: 100%;
            padding: 0 10px;
            cursor: pointer;
            color: rgba(122, 88, 24, 0.7);
            font-size: 30px;
            line-height: 80px;
            &.active {
              position: relative;
              color: rgba(122, 88, 24, 1);
              &::after {
                content: ''; /* 必须设置content属性，即使为空 */
                position: absolute;
                left: 50%;
                right: 0; /* 从左到右延伸 */
                bottom: 12px; /* 调整下横线与文字的距离 */
                height: 4px; /* 下横线的高度 */
                width: 52px;
                transform: translateX(-50%);
                background-color: rgba(122, 88, 24, 1); /* 下横线的颜色 */
              }
            }
        }
    }
}
.footer {
    width: 100%;
    height: 182px;
    background: rgba(102, 94, 78, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    a {
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      font-size: 36px;
      line-height: 51px;
      text-decoration: none;
    }
}
</style>
