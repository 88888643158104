import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history', // 使用 HTML5 History 模式，去掉 URL 中的 #
  base: '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/Home.vue'),
    },
    // {
    //   path: '/home',
    //   name: 'Home',
    //   component: () => import('../Home.vue')
    // },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/About.vue')
    }
  ]
});